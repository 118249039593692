.otp-verification-input {
  height: 50px;
  width: 70px !important;
  margin-right: 0px;
}

.forgot-row {
  justify-content: space-between;
}

.w-100-full {
  width: 100%;
}
